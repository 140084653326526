import React from "react";
import { Layout } from "~/templates/Layout";
import { CaseCard } from "~/molecules/CaseCard";
import { graphql } from "gatsby";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { siteWidth } from "~/utils/variables";
import { CaseCompanyLogos } from "~/molecules/CaseCompanyLogos";
import { PageHeading } from "~/atoms/PageHeading";
import { Pager } from "~/molecules/Pager";

type Props = {
  data: {
    allContentfulCase: GatsbyTypes.ContentfulCaseConnection;
  };
  pageContext: {
    limit: number;
    skip: number;
    totalPages: number;
    currentPage: number;
  };
};

const IndexCase: React.FC<Props> = ({ data, pageContext }) => {
  const { currentPage, totalPages } = pageContext;

  return (
    <>
      <Layout
        title={PATH.CASES.name}
        ogpDescription={`業種・業態・採用職種を問わず850社を超える導入実績の中から多数の成果をあげている企業様をご紹介します。`}
        breadCrumbItems={[PATH.TOP, PATH.CASES]}
      >
        {currentPage === 1 && (
          <PageHeading title={PATH.CASES.name}>
            業種・業態・採用職種を問わず850社を超える導入実績。
            <br />
            多数の成果をあげている企業様の一部をご紹介
          </PageHeading>
        )}
        {currentPage === 1 && <CaseCompanyLogos />}
        <div className="wrapper">
          <div className="cases">
            <ul className="caseCards">
              {data.allContentfulCase.edges.map((edge) => (
                <CaseCard
                  key={edge.node.contentful_id}
                  article={edge.node}
                  href={`/cases/${edge.node.contentful_id}`}
                />
              ))}
            </ul>
          </div>
          <Pager currentPage={currentPage} totalPages={totalPages} basePath={PATH.CASES.to} />
        </div>
      </Layout>
      <style jsx>{`
        .wrapper {
          background: ${colors.bgGrayGradient};
          margin: 40px auto 0;
          padding: 0 0 40px;
        }
        .cases {
          padding: 0 30px;
          display: flex;
          margin: 0 auto;
          max-width: ${siteWidth.default};
        }
        .caseCards {
          display: flex;
          flex-wrap: wrap;
          position: relative;
        }
      `}</style>
    </>
  );
};

export default IndexCase;

export const IndexCaseQuery = graphql`
  query IndexCase($limit: Int, $skip: Int) {
    allContentfulCase(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          id
          contentful_id
          company {
            name
          }
          date
          ogpImage {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;
