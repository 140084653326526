import React from "react";
import { colors } from "~/utils/colors";
import { CompanyLogos } from "~/molecules/CompanyLogos";
import { siteWidth } from "~/utils/variables";

export const CaseCompanyLogos: React.FC = () => (
  <>
    <style jsx>{`
      .wrapper {
        background-color: ${colors.white};
        margin: 40px auto 0;
      }
      .companyLogos {
        display: flex;
        margin: 0 auto;
        max-width: ${siteWidth.default};
      }
    `}</style>
    <div className="wrapper">
      <div className="companyLogos">
        <CompanyLogos />
      </div>
    </div>
  </>
);
